.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
}

.card-image:hover {
  transform: translateY(-10px);
  box-shadow: 2px 15px 10px 5px rgba(0, 0, 0, 0.5);
  transition-duration: 0.3s;
  transition-property: transform, box-shadow;
}

.card-image {
  box-shadow: 2px 5px 10px 0px black;
}
