.sorted {
  margin: 2rem;
}

.sorted-label {
  padding: 10px;
}

.size-label {
  padding: 10px;
}

.set-heading {
  margin: 1rem;
  font-size: 20px;
}

.drop-style {
  border-radius: 5px;
  margin-right: 5px;
  height: 25px;
  font-size: 15px;
}

.drop-page-style {
  border-radius: 5px;
  height: 25px;
  font-size: 15px;
}

@media only screen and (min-width: 1024px) {
  .wrap-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
