html,
body,
#root {
  background-color: solid white;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  z-index: 2;
}
