.pages-container {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.button-styling {
  display: flex;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #c9c9c9;
  border: none;
}

.button-styling:hover {
  opacity: 0.1;
}

.current-page-button {
  background-color: #757575;
}

.page-number {
  color: black;
}

.pages {
  padding: 5px;
}
