.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 100px;
}

.home-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: white;
  z-index: 100;
  padding: 20px 0px;
  margin-top: 100px;
}

.footer-position {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
}

.tcg-link {
  color: #3273dc;
}

.home-content .card {
  display: block;
  position: absolute;
  width: 80%;
  max-width: 270px;
}

.charizard {
  bottom: -270px;
  margin: 0 auto;
  left: 0;
  right: 0;
  border-radius: 40px;
}

.blastoise {
  bottom: -240px;
  margin: 0 auto;
  left: -310px;
  right: 0;
  border-radius: 40px;
}

.venusaur {
  bottom: -240px;
  margin: 0 auto;
  left: 0;
  right: -310px;
  border-radius: 40px;
}

/*===================*/
/* BOB
/*===================*/
@keyframes hvr-bob {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1em);
  }
  100% {
    transform: translateY(0);
  }
}
.hvr-bob:hover {
  animation-name: hvr-bob;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.errorMessage {
  color: red !important;
  margin: 0.5rem;
}

.title-home {
  color: white !important;
}

.footer-text {
  line-height: 1.5rem;
}

.search-by {
  padding-bottom: 1rem;
}

.search-style {
  border-radius: 5px;
  height: 25px;
  font-size: 15px;
}

@media only screen and (min-width: 1024px) {
  .home-footer {
    margin-top: 210px;
  }

  .blastoise,
  .venusaur {
    bottom: -340px;
  }

  .charizard {
    bottom: -370px;
  }

  .footer-position {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
