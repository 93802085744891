.summary-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.summary-card {
  width: 70%;
  height: 30%;
  box-shadow: 2px 5px 10px 0px black;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-left {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  align-items: center;
}

.title-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
}

.cardName {
  margin: 1rem;
  padding-top: 20px;
}
.stage {
  margin: 0;
}

.hp {
  margin: 10px;
}

.line {
  padding-top: 10px;
}

.price-column {
  display: flex;
  flex-direction: column;
  margin: 5px;
  flex: 1;
  justify-content: space-between;
}

.price-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

.price-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 15px;
}

.prices-header {
  margin: 0;
}

.updated-header {
  margin: 0;
}

.ability,
.attacks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.weakness-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.weakness-content {
  flex: 1 0 100px;
  margin: 0 5px;
}

.rarity {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rarity-column {
  flex: 1;
}

.set-img {
  width: 50%;
}

.flavor {
  color: rgb(110, 110, 110);
  width: 80%;
  font-size: 15px;
  padding-left: 10px;
}

.purple {
  color: #824c95 !important;
  font-size: 15px;
  margin: 0;
}

.green {
  color: #48c774 !important;
  font-size: 15px;
  margin: 0;
}

.blue {
  color: #3273dc !important;
  font-size: 15px;
  margin: 0;
}

.red {
  color: #f14668 !important;
  font-size: 15px;
  margin: 0;
}

.buy {
  font-weight: bolder;
  text-decoration: none;
}

.buy-color {
  color: #3273dc !important;
  margin: 0;
}

.flavor-container {
  display: flex;
  justify-content: center;
}

.attacks-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: 750px) {
  .summary-container {
    margin-top: 0px;
    flex-direction: row;
  }

  .summary-card {
    width: auto;
    height: 330px;
    padding: 40px 100px 0 40px;
    box-shadow: none;
  }

  .row {
    flex-direction: row;
    align-items: normal;
  }

  .title {
    flex-direction: row;
    justify-content: space-between;
  }

  .ability-width {
    width: 100%;
  }

  .flavor {
    width: 100%;
    padding-left: 0;
  }

  .cardName {
    width: 100%;
    margin: 0;
  }

  .weakness-content {
    flex: 1 0 25%;
  }

  .weakness-content p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1120px) {
  .summary-card {
    width: auto;
    height: 500px;
    padding: 40px 100px 0 40px;
    box-shadow: none;
  }
}
