.theme {
  background-color: #1a1919;
  color: #999;
  height: 100%;
  transition: background-color 0.25s ease-in-out;
}

.light-mode {
  color: #333;
  height: 100%;
  transition: background-color 0.25s ease-in-out;
}

.span {
  font-size: 40px;
}

.container-themes {
  display: flex;
  justify-content: center;
  align-items: center;
}

body.theme,
body.theme .navbar {
  background-color: #1a1919 !important;
  color: #999;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
}

body.theme p,
body.theme h1,
body.theme h2,
body.theme h3,
body.theme h4,
body.theme h5,
body.theme h6 {
  color: #c9d1d9;
  transition: color 0.25s ease-in-out;
}

body.theme .battle,
body.theme .legalities-style,
body.theme .set-title {
  color: #c9d1d9;
}

body.theme .released {
  color: #818a93;
}

body.theme .set-content {
  background-color: #252f3f;
}

body.theme .sets-container {
  background-color: #1a202c;
}

body.theme .navbar,
body.theme .nav-menu.active {
  background-color: #1a202c;
}

body.theme .left-container-text {
  color: white !important;
}

body.theme .summary-container,
body.theme .home-container {
  background-color: #1a202c !important;
}

body.theme .footer-text,
body.theme .menu-bars {
  color: #c9d1d9 !important;
}

body.theme .link-style {
  color: #c9d1d9 !important;
}

body.theme .home-footer {
  background-color: #1a202c;
}

@media only screen and (min-width: 1024px) {
  .light-mode {
    display: flex;
  }
}
