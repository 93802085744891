.sets-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.set-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 1rem;
}

.set-content {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 1em;
  background-color: #fff;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-property: transform;
}

.set-content:hover {
  transform: scale(1.1);
}

.set-middle {
  display: flex;
}

.set-middle-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sets-img {
  max-width: 100%;
  max-height: 100%;
}

.set-logo {
  height: auto;
  width: 100%;
}

.set-legal {
  display: flex;
  flex-direction: column;
}

.figure-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  height: 140px;
  margin: 0;
}

.figure-logo {
  margin: 15px;
  width: 48px;
  height: 48px;
}

.battle {
  font-weight: 400;
  font-size: 1.5rem;
  color: black;
}

.released {
  margin-top: -1.25rem;
  font-size: 1rem;
  color: black;
}

.legalities-style {
  margin-left: 2em;
  margin-top: 1em;
}

.set-title {
  color: black;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.125;
}

@media only screen and (min-width: 1024px) {
  .set-title {
    display: flex;
    justify-content: center;
  }

  .set-container {
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .set-content {
    width: auto;
    height: auto;
    vertical-align: middle;
    transition-duration: 0.3s;
    transition-property: transform;
    flex: 0 1 300px;
    justify-content: stretch;
  }
}
