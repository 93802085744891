.navbar {
  background-color: #fff;
  max-height: 100px;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
  align-items: stretch;
  width: 100%;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  justify-content: space-between;
}

.left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left-container-text {
  color: #0a0a0a !important;
  padding: 4px;
  font-weight: bold;
}

.menu-bars {
  display: flex;
  align-items: center;
  margin-left: 0rem;
  font-size: 2rem;
  background: none;
  padding-right: 15px;
}

.nav-menu {
  background-color: #fff;
  width: 100%;
  height: 60vh;
  position: absolute;
  top: 83px;
  left: -100%;
  transition: 0ms;
  z-index: 999;
}

.nav-menu.active {
  left: 0;
  transition: 0ms;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}

.nav-text {
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  color: black;
}

.searchBar {
  padding-bottom: 20px;
}

.nav-text-red {
  color: white;
  list-style: none;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  padding: 0 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.nav-text button {
  text-decoration: none;
  color: white;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  background: none;
  border: none;
  border-radius: 4px;
}

.nav-text a:hover {
  opacity: 0.1;
}

.nav-text button:hover {
  background-color: rgb(204, 204, 204);
}

.navbar-toggle a:hover {
  background-color: rgb(204, 204, 204);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-bars:hover {
  text-decoration: none;
  opacity: 0.1;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  cursor: pointer;
}

.nav-menu-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -6px;
  list-style: none;
  padding: 0;
}

.nav-menu-items li {
  margin: 5px 0;
  font-size: 18px;
}

.navbar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.link-style,
a {
  color: black;
  text-decoration: none;
}

a {
  background: transparent;
  outline: none;
  cursor: pointer;
}

.nav-img {
  width: 77px;
  height: auto;
  transform: scaleX(-1);
}

.price-column > h5 {
  font-size: 11px;
}

@media only screen and (min-width: 410px) {
  .left-container-text {
    padding: 10px;
    color: white;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
  }

  .nav-menu.active {
    top: 76px;
    height: 40vh;
  }

  .prices-header {
    padding-left: 0 !important;
  }

  .buy {
    padding: 0;
    padding-left: 0 !important;
  }

  .updated-header {
    padding: 0;
    padding-left: 0 !important;
  }

  .price-row {
    padding-left: 0 !important;
    width: auto !important;
    justify-content: space-between;
  }

  .price-top {
    margin: 5px;
  }

  .price-column {
    margin: 5px;
  }

  .ability-name {
    display: flex;
    justify-content: center;
  }

  .attacks-width {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .menu-bars {
    display: none;
  }

  .nav-menu {
    display: contents;
  }

  .nav-menu-items {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 50px;
    margin-top: -70px;
    padding-left: 250px;
    align-items: center;
  }

  .nav-menu-items li {
    font-size: 20px;
    align-items: center;
    padding: 10px;
    justify-content: flex-end;
  }
}
